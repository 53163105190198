// "use server";
import { bitrix } from "../../lib/util";

export const bitrixLeadAction = async (data, queryParams, sourceId) => {

   // Get params from request
  const click_id = localStorage.getItem("click_id") || "";
  const utm_source = localStorage.getItem("utm_source") || "";
  const utm_medium = localStorage.getItem("utm_medium") || "";
  const utm_campaign = localStorage.getItem("utm_campaign") || "";
  const utm_term = localStorage.getItem("utm_term") || "";

  const leadData = JSON.stringify({
    fields: {
      TITLE: "learnerspoint.org",
      UF_CRM_1695804853691: utm_source || "",
      UF_CRM_1695804234321: utm_campaign || "",
      UF_CRM_1695804262987: utm_medium || "",
      UF_CRM_1695804290715: utm_term || "",
      UF_CRM_1739521212650: click_id || "",
      NAME: data?.name,
      country_name: "",
      course_id: data?.course?.id || 2304,
      STATUS_ID: "NEW",
      OPENED: "Y",
      ASSIGNED_BY_ID: 1,
      PHONE: [
        {
          VALUE: data?.phone,
          VALUE_TYPE: "WORK",
        },
      ],
      EMAIL: [
        {
          VALUE: data?.email,
          VALUE_TYPE: "WORK",
        },
      ],
      COMMENTS: data?.message,
      SOURCE_ID: sourceId,
      UF_CRM_1584949782: "sw",
      UF_CRM_1584950014: "",
      params: {
        REGISTER_SONET_EVENT: "Y",
      },
    },
  });

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", "qmb=0.");

  try {
    const leadReq = await fetch(bitrix.leadUrl, {
      method: "POST",
      body: leadData,
      headers: myHeaders,
    });

    const leadRes = await leadReq.json();

    const lead_id = leadRes?.result;
    if (lead_id) {

      const rows = [
        {
          PRODUCT_ID: data?.course?.productId || 4648,
          PRICE: data?.course?.price || data?.course?.discountPrice || 0,
          QUANTITY: 1,
        }
      ];

      const reqBody = JSON.stringify({
        id: lead_id,
        rows: rows,
      });

      const leadAddReq = await fetch(bitrix.leadAddUrl, {
        method: "POST",
        body: reqBody,
        headers: myHeaders,
      });

      const leadAddRes = await leadAddReq.json();

      return leadAddRes;
    }
  } catch (error) {
    console.error(error);
  }
};
