'use client';
// import {
//   Button,
//   Modal,
//   ModalBody,
//   ModalContent,
//   useDisclosure,
// } from "@nextui-org/react";
import React from "react";
// import { useDictionary } from "../../context/dictionaryProvider";
import { Avtar, CheckMark, ContactWrapShape, SendBtn } from "../molecules";

const ThankuModal = ({ message, btnText, handleClick,course }) => {
  // const dict = useDictionary();
  // const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const trackCloseClick = (menuName) => {
    webengage.track('EnquiryNowCompleted', {
      category: 'EnquiryNowCompleted',
      course: course.name,
      label: menuName,
      value: 1,
    });
  };
  return (
    <div>
      <>
        {/* <Button
          className="border border-black rounded-full bg-transparent text-black font-semibold"
          onPress={onOpen}
        >
          Sample
        </Button>
        <Modal
          backdrop="blur"
          className=""
          isOpen={isOpen}
          size="sm"
          onOpenChange={onOpenChange}
          classNames={{
            backdrop: "blur-md",
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody> */}
                  <div className="relative px-12 pt-10 pb-32 my-12 mx-auto rounded-2xl border border-black max-w-full flex flex-col justify-center items-center text-center">
                    <Avtar space="mb-0" />
                    <CheckMark />
                    <h3 className="text-[32px] text-[#303030] font-extrabold mt-8">
                      Great!
                    </h3>
                    <p className="font-semibold">{message}</p>
                    {btnText && (
                      <>
                        <SendBtn onClick={()=>{ handleClick();
                           trackCloseClick(
                            'Enquiry Now Completed',
                          );
                        }} type="submit">
                          {btnText}
                        </SendBtn>
                        <ContactWrapShape space="!right-[-1px] !bottom-[-1px]" />
                      </>
                    )}
                  </div>
                {/* </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal> */}
      </>
    </div>
  );
};

export default ThankuModal;
